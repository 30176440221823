import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { subscribe } from 'klaviyo-subscribe'

import { fbTrack } from 'src/utils/tracking'

import { darken, rgba } from 'polished'
import { colors, typography, animations, util, mq } from 'src/styles'
import ConditionalRender from 'src/components/ConditionalRender'
import Button from 'src/components/Button'
import Grid from 'src/components/Grid'
import MaterialIcon from 'src/components/MaterialIcon'
import { isEmoji, validateEmail } from 'src/utils/validations'
import { inputThemes as themes } from 'src/styles/themes'
import { MdArrowForward, MdCheck, MdPriorityHigh } from 'react-icons/md'

const inputVars = {
	tiny: '36px',
	small: '48px',
	medium: '50px',
	large: '72px',
	borderWidth: '2px',
	backgroundColor: 'transparent',
	borderRadius: '0px',
	hPadding: '1em'
}

const setInputTheme = theme => {
	return `
		color: ${ themes[theme].color };
		input {
			background: ${ themes[theme].background };
			border-color: ${ themes[theme].borderColor };
			caret-color: ${ themes[theme].color };
			color: ${ themes[theme].color };
			&:active,
			&:focus,
			&:active:hover,
			&:focus:hover {
				background: ${ darken(0.05, themes[theme].focusBackground) };
				border-color: ${ themes[theme].focusBorderColor };
			}
			&:hover {
				background: ${ darken(0.05, themes[theme].hoverBackground) };
				border-color: ${ themes[theme].hoverBorderColor };
			}
			&:-internal-autofill-selected,
			&:-webkit-autofill {
				background: ${ darken(0.05, themes[theme].background) } !important;
				border-color: ${ themes[theme].borderColor };
				-webkit-text-fill-color: ${ themes[theme].color } !important;
				color: ${ themes[theme].color } !important;
			}
			::placeholder {
				color: ${ rgba(themes[theme].color, 1) } !important;
			}
		}
	`
}

const getState = (loading, error, success, disabled) => {
	let buttonState = ''
	if (error) {
		buttonState = 'error'
	} else if (loading) {
		buttonState = 'loading'
	} else if (success) {
		buttonState = 'success'
	} else if (disabled) {
		buttonState = 'disabled'
	}

	return buttonState
}

const InputWrap = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	${ typography.body }
	font-weight: 500;
	${ ({ theme }) => setInputTheme(theme) }
`

const InputStyles = (state, size, icon, iconPosition, theme, label) => (`
	appearance: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-touch-callout: none;
	outline: none;
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	background: ${ inputVars.backgroundColor };
	border: ${ inputVars.borderWidth } solid;
	height: ${ inputVars.medium };
	line-height: 1em;
	text-transform: inherit;
	letter-spacing: 0;
	border-radius: ${ inputVars.borderRadius };
	color: inherit;
	font-style: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-align: left;
	box-shadow: none;
	padding: 2px ${ inputVars.hPadding } 0;
	${ icon ? `
		padding-${ iconPosition }: ${ inputVars.medium };
		${ size === 'tiny' ? `
			${ util.responsiveStyles('font-size', 16, 14, 13, 13) }
			padding-${ iconPosition }: ${ inputVars.tiny };
		` : `` }
		${ size === 'small' ? `
			padding-${ iconPosition }: ${ inputVars.small };
		` : `` }
		${ size === 'large' ? `
			padding-${ iconPosition }: ${ inputVars.medium };
		` : `` }
	` : `` }
	padding-bottom: 1px;
	${ util.fontSmoothing }
	transition: background ${ animations.mediumSpeed } ease-in-out,
							color ${ animations.mediumSpeed } ease-in-out,
							border ${ animations.mediumSpeed } ease-in-out,
							box-shadow ${ animations.mediumSpeed } ease-in-out,
							transform ${ animations.mediumSpeed } ease-in-out,
							opacity ${ animations.mediumSpeed } ease-in-out;
	// Input States
	::placeholder {
		color: ${ colors.lightTextColor };
	}
	${ state === 'disabled' ? `cursor: not-allowed;` : `` }
	${ state === 'loading' ? `cursor: wait;` : `` }
	${ state === 'error' ? `
		border-color: ${ colors.alert };
	` : `` }

	${ size === 'tiny' ? `
		height: ${ inputVars.tiny };
		${ util.responsiveStyles('font-size', 16, 14, 13, 13) }
	` : `` }
	${ size === 'small' ? `
		height: ${ inputVars.small };
	` : `` }
	${ size === 'large' ? `
		height: ${ inputVars.large };
	` : `` }

	${ label ? `${ util.responsiveStyles('padding-top', 18, 16, 16, 14) }` : `` }

`)

const StyledInput = styled.input`
	${ ({
	loading,
	error,
	success,
	disabled,
	size,
	icon,
	iconPosition,
	label,
	theme,
	style,
}) => InputStyles(getState(loading, error, success, disabled), size, icon, iconPosition, theme, label) }
`

const InputIcon = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	pointer-events: none;
	overflow: hidden;
	${ ({ emojiIcon }) => emojiIcon && `
		padding-top: .3em;
		font-size: 18px;
		line-height: 1em;
	` }
	${ ({ iconPosition }) => iconPosition }: ${ inputVars.borderWidth };
	width: ${ inputVars.medium };
	height: ${ inputVars.medium };
	${ ({ size }) => size === 'tiny' ? `
		width: ${ inputVars.tiny };
		height: ${ inputVars.tiny };
	` : `` }
	${ ({ size }) => size === 'small' ? `
		width: ${ inputVars.small };
		height: ${ inputVars.small };
	` : `` }
	${ ({ size }) => size === 'large' ? `
		width: ${ inputVars.medium };
		height: ${ inputVars.large };
	` : `` }
	span, svg {
		display: block;
	}
`

const InputLabel = styled.label`
	position: absolute;
	font-style: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	top: 0;
	left: ${ inputVars.borderWidth };
	height: 100%;
	display: flex;
	align-items: center;
	pointer-events: none;
	margin: 0 ${ inputVars.hPadding };
	color: ${ ({ error }) => error ? `${ colors.alert }` : `inherit` };
	transition: transform ${ animations.mediumSpeed } ease-in-out, color ${ animations.mediumSpeed } ease-in-out;
	transform-origin: 0% 50%;
	${ props => props.placeholder || props.value || props.focused ? `
		transform: translate3d(0, -10px, 0) scale(.75);
	` : `` }
	${ props => props.focused ? `
		color: ${ themes[props.theme].color };
	` : `` }
	${ ({ icon, iconPosition, size }) => icon ? `
		margin-${ iconPosition }: ${ inputVars.medium };
		${ size === 'tiny' ? `
			height: ${ inputVars.tiny };
			${ util.responsiveStyles('font-size', 16, 14, 13, 13) }
			margin-${ iconPosition }: ${ inputVars.tiny };
		` : `` }
		${ size === 'small' ? `
			height: ${ inputVars.small };
			margin-${ iconPosition }: ${ inputVars.small };
		` : `` }
		${ size === 'large' ? `
			height: ${ inputVars.large };
			margin-${ iconPosition }: ${ inputVars.large };
		` : `` }
	` : `` }
`

const Row = styled.div`
	display: flex;
	justify-content: space-between;
`

const ButtonStyled = styled(Button)`
	margin-left: 10px;
	min-width: 0;
	${ mq.smallAndBelow } {
		min-width: 50px;
		max-width: 50px;
		margin-left: 0;
		border-left: none;
	}
`

const ArrowIcon = styled(MdArrowForward)`
	display: inline-block;
	vertical-align: middle;
	margin-left: 0;
	margin-top: 0;
`

const CheckIcon = styled(MdCheck)`
	display: inline-block;
	vertical-align: middle;
	margin-left: 0;
	margin-top: 0;
`

const AlertIcon = styled(MdPriorityHigh)`
	display: inline-block;
	vertical-align: middle;
	margin-left: 0;
	margin-top: 0;
`

const HideMobile = styled.div`
	${ mq.smallAndBelow } {
		display: none;
	}
`
const ShowMobile = styled.div`
	${ mq.mediumAndUp } {
		display: none;
	}
	${ mq.smallAndBelow } {
		display: inline-block;
	}
`

const StyledGrid = styled(Grid)`
	margin-bottom: 10px;
`

class Input extends Component {

	constructor (props) {
		super(props)
		this.state = {
			focused: false,
			hasValue: false,
			email: '',
			firstName: '',
			lastName: '',
			success: null,
			error: null,
			emailValid: true,
		}
	}

	renderIcon = (icon, size, iconPosition, theme) => {
		let renderedIcon = false
		let isEmojiIcon = isEmoji(icon)
		if (isEmojiIcon) {
			renderedIcon = <InputIcon size={size} iconPosition={iconPosition} theme={theme} emojiIcon>{icon}</InputIcon>
		} else if (typeof icon === 'string') {
			renderedIcon = <InputIcon size={size} iconPosition={iconPosition} theme={theme}><MaterialIcon size={this.props.size === 'tiny' && '18px'}>{icon}</MaterialIcon></InputIcon>
		} else {
			renderedIcon = <InputIcon size={size} iconPosition={iconPosition} theme={theme}>{icon}</InputIcon>
		}
		return renderedIcon
	}

	setFocus = status => {
		this.setState({ focused: status })
	}

	setEmail = x => {
		const email = x && x.target && x.target.value
		const emailValid = validateEmail(email)
		this.setState({ email, emailValid })
	}

	setFirstName = x => {
		const firstName = x && x.target && x.target.value
		this.setState({ firstName })
	}

	setLastName = x => {
		const lastName = x && x.target && x.target.value
		this.setState({ lastName })
	}

	submit = () => {
		// console.log('submit email please')
		// Props and fallback to site settings
		const listId = this.props.listId || process.env.GATSBY_KLAVIYO_LIST_ID
		const { email, firstName, lastName } = this.state

		const options = {}

		if (this.props.firstName) {
			options['$first_name'] = firstName
		}

		if (this.props.lastName) {
			options['$last_name'] = lastName
		}

		fbTrack('track', 'Lead')
		// console.log(listId)
		// console.log(email)
		// console.log(options)
		subscribe(listId, email, options).then(response => {
			// console.log(response)
			if (response && response.success) {
				this.setState({ success: true, error: false })
			} else {
				this.setState({ success: false, error: true })
			}
		})
	}

	render () {

		const {
			value,
			type,
			icon,
			iconPosition,
			loading,
			disabled,
			buttonText,
			onClick,
			theme,
			className,
			shape,
			size,
			firstName,
			lastName,
			placeholder,
			label,
			spellcheck,
			name,
			header
		} = this.props

		const { focused, error, success } = this.state

		return (
			<React.Fragment>
				<h6 css={css`margin-bottom: 1em;`}>{header}</h6>

				{(firstName || lastName) && (
					<Row>
						<StyledGrid
							small="[12]"
							medium={firstName && lastName ? "[6] [6]" : "[12]"}
							large={firstName && lastName ? "[6] [6]" : "[12]"}
							colGap="10px"
							rowGap="10px"
						>
						{firstName && (
							<div>
								<InputWrap theme={theme || 'default'}>
									<StyledInput
										type="text"
										placeholder={'Enter First Name'}
										theme={theme || 'default'}
										onChange={x => this.setFirstName(x)}
										shape={shape}
										size={size}
									/>
								</InputWrap>
							</div>
						)}
						{lastName && (
							<div>
								<InputWrap theme={theme || 'default'}>
									<StyledInput
										type="text"
										placeholder={'Enter Last Name'}
										theme={theme || 'default'}
										onChange={x => this.setLastName(x)}
										shape={shape}
										size={size}
									/>
								</InputWrap>
							</div>
						)}
						</StyledGrid>
					</Row>
				)}

				<Row>
					<InputWrap
						className={className}
						theme={theme || 'default'}
					>
						<StyledInput
							type={type}
							placeholder={'Enter Email'}
							icon={icon}
							iconPosition={iconPosition}
							loading={loading}
							error={error}
							success={success}
							disabled={disabled}
							onClick={onClick}
							theme={theme || 'default'}
							shape={shape}
							size={size}
							onFocus={() => this.setFocus(true)}
							onBlur={() => this.setFocus(false)} // needs work
							onChange={x => this.setEmail(x)}
							value={value}
							label={label}
							name={name}
							spellCheck={spellcheck}
						/>
						<ConditionalRender condition={label}>
							<InputLabel
								icon={icon}
								iconPosition={iconPosition}
								size={size}
								error={error}
								theme={theme || 'default'}
								value={value}
								htmlFor={name}
								focused={focused}
								placeholder={placeholder}
								className={placeholder || value || focused ? 'focused' : 'unfocused' /* to select from styled component */}
							>
								{label}
							</InputLabel>
						</ConditionalRender>
						{icon && (
							this.renderIcon(icon, size, iconPosition, theme)
						)}
					</InputWrap>
					<ButtonStyled
						disabled={!this.state.emailValid || this.state.success}
						theme={theme || 'default'}
						setTheme={theme || 'default'}
						onClick={this.submit}
					>
						{success && (
							<div>
								<HideMobile>
									Success
								</HideMobile>
								<CheckIcon size={24} />
							</div>
						)}
						{error && (
							<div>
								<HideMobile>
									Error
								</HideMobile>
								<AlertIcon size={24} />
							</div>
						)}

						{!error && !success && (
							<div>
								<HideMobile>
									{buttonText}
								</HideMobile>
								<ShowMobile>
									<ArrowIcon size={24} />
								</ShowMobile>
							</div>
						)}
					</ButtonStyled>
				</Row>
			</React.Fragment>
		)
	}
}

Input.defaultProps = {
	type: 'text',
	iconPosition: 'left',
	theme: 'default',
	spellcheck: false,
	onChange: () => { }
}

export default Input
